import * as React from 'react'
import { StoreProvider } from './src/context/store-context'
import { FormProvider } from './src/context/form.context'
import { LangProvider } from './src/context/lang.context'

import './src/style/style.css'

export const wrapRootElement = ({ element }) => (
  <LangProvider>
    <StoreProvider>
      <FormProvider>{element}</FormProvider>
    </StoreProvider>
  </LangProvider>
)
